<template>
  <div class="word-book-list">
    <div class="back">
      <span @click="prev"
        ><i class="iconfont icon-class-right"></i>{{ i18n.Word.return }}</span
      >
    </div>
    <div class="header-content">
      <h3>{{ info.name }}</h3>
      <p>{{ info.remark }}</p>
      <div class="list">
        <div class="item">
          {{ statusInfo.status_1 }}{{ i18n.Word.NotLearned }}
        </div>
        <div class="item">{{ statusInfo.status_2 }}{{ i18n.Word.Learned }}</div>
        <div class="item">
          {{ statusInfo.status_3 }}{{ i18n.Word.NotMastered }}
        </div>
      </div>
    </div>
    <h2>{{ i18n.Word.WordList }}</h2>
    <div class="select-content">
      <ul>
        <li
          v-for="item of statusList"
          :key="item.id"
          :class="{ active: status === item.id }"
          @click="handleStatus(item.id)"
        >
          <span>{{ item.name }}</span>
        </li>
        <div class="line"></div>
        <li @click="handleClearAll">
          <i class="iconfont icon-weixiao1" /><span>{{
            $t('message.Word.clearAll')
          }}</span>
        </li>
        <li class="sort-select" v-if="!isUser">
          <el-dropdown @command="handleSortChange">
            <span>
              <span>{{ sortSelectItem.name }}</span>
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item of sortOpts"
                  :key="item.key"
                  :command="item.key"
                >
                  <section class="sort-item">
                    <!--                  <i-->
                    <!--                    class="iconfont"-->
                    <!--                    :class="item.icon"-->
                    <!--                    :style="item.iconStyle"-->
                    <!--                  />-->
                    <span>{{ item.name }}</span>
                  </section>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
      </ul>
      <div class="right">
        <span class="total"
          >{{ i18n.Word.total }} {{ total }} {{ i18n.Word.Words }}</span
        >
        <el-button
          round
          size="medium"
          type="info"
          class="learn-btn"
          @click="goLearn()"
          :disabled="disabledLearn"
          >{{ i18n.Word.learn }}
        </el-button>
      </div>
    </div>
    <div class="word-book-list-content">
      <div class="word-list-content" :class="{ hasList: list && list.length }">
        <div class="word-list-header">
          <p class="name">{{ i18n.Word.word }}</p>
          <p class="phonetic">{{ i18n.Word.Phonetic }}</p>
          <p class="explains">{{ i18n.Word.textInterpretation }}</p>
          <p class="join">{{ i18n.Word.AddWew }}</p>
          <p class="checkbox">{{ i18n.Word.mastered }}</p>
        </div>
        <ul v-loading="loading">
          <template v-if="list && list.length">
            <li v-for="(item, index) of list" :key="item.id">
              <div class="name">
                <span class="words-name">
                  <span @click="goLearn(item.id)">{{ item.name }}</span>
                  <i
                    v-if="isUser"
                    class="f-icon icon-del del-btn"
                    @click.stop="handleDel(item.id)"
                  />
                </span>
                <span>
                  <i
                    class="f-icon icon-trumpet play-btn"
                    @click.stop="clickVolume(index)"
                  />
                  <audio :src="item.url" ref="audio" />
                </span>
              </div>
              <div class="phonetic">/{{ item.phonetic }}/</div>
              <div class="explains">
                <div
                  v-for="(explains, explainsIndex) in item.explain"
                  :key="explainsIndex"
                >
                  {{ explains }}
                </div>
              </div>
              <div class="join">
                <TranslateWord styleType="color" :word="item.name">
                  <i class="iconfont icon-tianjia"></i>
                </TranslateWord>
              </div>
              <div class="checkbox">
                <Checkbox
                  v-model="item.know_status"
                  :true-label="1"
                  :false-label="0"
                  class="word-checkbox"
                  @change="handleKnow(item)"
                ></Checkbox>
              </div>
            </li>
          </template>
        </ul>
        <Empty v-if="!loading && (!list || !list.length)" />
      </div>
      <el-pagination
        v-if="list && list.length"
        small
        layout="prev, pager, next"
        class="word-list-pagination"
        @current-change="fetchList"
        :page-count="pagination.pageCount"
        :page-size="pagination.pageSize"
        :current-page.sync="pagination.currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  apiWordList,
  apiBaseWordList,
  apiWordDel,
  apiBaseWordClear,
  apiUserWordClear,
  apiWordUserMark,
  apiWordBaseMark,
  apiBaseWordRandList
} from '@/api/api.js'
import Empty from '@/components/Empty'
import Checkbox from './components/check'
import TranslateWord from '@/components/TranslateWord/index'
import i18n from '@/i18n/i18n'

const STATUS_LIST = [
  {
    name: i18n.locale === 'ch' ? '全部' : 'All',
    id: 0
  },
  {
    name: i18n.locale === 'ch' ? '未学习' : 'Not learning',
    id: 1
  },
  {
    name: i18n.locale === 'ch' ? '已学习' : 'Learned',
    id: 2
  },
  {
    name: i18n.locale === 'ch' ? '未掌握' : 'Not mastered',
    id: 3
  }
]
const genRandKey = () => `${new Date().getTime()}`
export default {
  components: {
    Empty,
    Checkbox,
    TranslateWord
  },
  data() {
    return {
      list: [],
      cate: this.$route.params.id,
      type: this.$route.query.type,
      statusList: STATUS_LIST,
      loading: false,
      pagination: {
        pageCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      status: 0,
      statusInfo: {
        status_1: 0,
        status_2: 0,
        status_3: 0
      },
      total: 0,
      info: {},

      sortkey: 1,
      sortRandKey: genRandKey()
    }
  },
  created() {
    this.fetchList()
  },
  computed: {
    sortSelectItem() {
      return this.sortOpts.find((m) => m.key === this.sortkey)
    },
    sortOpts() {
      return [
        {
          key: 1,
          name: this.i18n.Word.defaultSorting,
          icon: 'icon-sequence',
          iconStyle: {
            fontSize: '12px',
            width: '18px',
            display: 'inline-block'
          }
        },
        {
          key: 2,
          name: this.i18n.Word.randomSort,
          icon: 'icon-Merge',
          iconStyle: {
            fontSize: '14px',
            width: '18px',
            display: 'inline-block'
          }
        }
      ]
    },
    isUser() {
      return this.type === 'user'
    },
    listApi() {
      return this.isUser
        ? apiWordList
        : this.sortkey === 1
        ? apiBaseWordList
        : apiBaseWordRandList
    },
    markApi() {
      return this.isUser ? apiWordUserMark : apiWordBaseMark
    },
    disabledLearn() {
      return this.statusInfo.status_4 === this.statusInfo.status_0
    },
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    handleSortChange(command) {
      this.sortkey = command
      if (2 === this.sortkey) {
        this.sortRandKey = genRandKey()
      }

      this.fetchList()
    },

    prev() {
      this.$router.go(-1)
    },
    goLearn(wordId = null) {
      this.$router.push({
        name: 'wordLearn',
        params: { cate: this.cate },
        query: {
          type: this.type,
          status: this.status === 0 ? 9 : this.status,
          wordId: wordId,
          randKey: this.sortkey === 2 ? this.sortRandKey : null
        }
      })
    },
    fetchList(page) {
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.pagination.currentPage = page
      }
      this.listApi({
        page: this.pagination.currentPage,
        page_size: 10,
        cate: this.cate,
        status: this.status,
        rand_key: this.sortRandKey
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.list = res.data.list
          this.pagination.pageCount = res.data.pager.page_cnt
          this.statusInfo = res.data.stat
          this.total = res.data.pager.total_cnt
          this.info = res.data.cate || {}
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickVolume(index) {
      this.$refs.audio[index].play()
    },
    handleStatus(status) {
      this.status = status
      this.fetchList(1)
    },
    handleKnow({ id, learn_status, know_status }) {
      this.markApi({
        id,
        learn_status: know_status || learn_status,
        know_status
      }).then((res) => {
        this.$layer.msg(res.msg)
        if (res.code !== 200) {
          return
        }
        this.fetchList()
      })
    },
    handleDel(id) {
      apiWordDel({
        id
      }).then((res) => {
        this.$layer.msg(res.msg)
        if (res.code !== 200) {
          return
        }
        this.fetchList(1)
      })
    },
    handleClearAll() {
      this.$confirm('本词库内所有“已掌握”将全部标记为“未掌握”', '确认重学', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const apiFunc = this.isUser ? apiUserWordClear : apiBaseWordClear
        apiFunc({
          cate: this.cate
        }).then((res) => {
          this.$layer.msg(res.msg)
          if (res.code !== 200) {
            return
          }
          this.fetchList(1)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.word-book-list {
  color: #1f1f40;

  h2 {
    font-size: 18px;
    margin: 22px 0 8px;
  }

  .back {
    font-size: 14px;
    margin: 6px 0 10px;

    i {
      display: inline-block;
      font-size: 12px;
      transform: rotateZ(180deg);
      vertical-align: text-top;
    }

    span {
      cursor: pointer;
    }
  }

  .header-content {
    padding: 30px 50px 30px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 10px;

    h3 {
      font-size: 24px;
    }

    p {
      color: #9c9c9c;
      font-size: 14px;
      margin: 7px 0 15px;
    }

    .list {
      display: flex;
      justify-content: space-between;

      .item {
        width: 32%;
        height: 65px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dcdcdc;
      }
    }
  }

  .select-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;
      align-items: center;

      .line {
        margin-right: 18px;
        height: 36px;
        border-right: 1px solid #f0f0f5;
      }

      li {
        min-width: 89px;
        height: 36px;
        margin-right: 18px;
        color: #1f1f40;
        line-height: 36px;
        text-align: center;
        background: #f4f4f4;
        border-radius: 50px;
        cursor: pointer;
        padding: 0px 16px;
        box-sizing: border-box;

        > i {
          margin-right: 6px;
          font-size: 14px;
        }

        &.active,
        &:hover {
          background: #3b86ff;
          color: #fff;
          &.sort-select {
            span {
              color: #fff;
            }
          }
        }

        &.sort-select {
          border-radius: 6px;
          span {
            font-size: 16px;
          }
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .total {
      margin-right: 18px;
      color: #9c9c9c;
      font-size: 14px;
    }

    .learn-btn {
      color: #ffffff;
      background: #3b86ff;
      border-color: #3b86ff;

      &:hover,
      &:focus {
        color: #ffffff;
        background: #3b86ff;
      }

      &.is-disabled {
        color: #fff;
        background-color: #c8c9cc;
        border-color: #c8c9cc;
      }
    }
  }

  .word-book-list-content {
    margin: 20px auto 0;

    .name {
      width: 300px;
      flex-shrink: 0;
    }

    .del-btn {
      font-size: 14px;
      color: #d5d5d5;
      cursor: pointer;
      margin-left: 9px;

      &:hover {
        color: #3b86ff;
      }
    }

    .phonetic {
      width: 250px;
      flex-shrink: 0;
    }

    .explains {
      width: 460px;
      word-break: break-word;
    }

    .join {
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: #bfbfbf;
        font-size: 18px;
        display: inline-block;
        transform: translateY(2px);

        &:hover {
          color: #3b86ff;
        }
      }
    }

    .checkbox {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .words-name {
      display: inline-block;
      width: 180px;

      > span {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .word-checkbox {
      /*margin-left: auto;*/
    }

    .el-checkbox__input {
      .el-checkbox__inner {
        &:hover {
          border: 1px solid #3b86ff;
        }
      }

      &.is-focus {
        .el-checkbox__inner {
          border-color: #d7e7ff;

          &:hover {
            border: 1px solid #3b86ff;
          }
        }
      }

      &.is-checked {
        .el-checkbox__inner {
          background-color: #3b86ff;
          border-color: #3b86ff;

          &::after {
            border: 2px solid #fff;
            border-left: 0;
            border-top: 0;
          }
        }
      }
    }

    .word-list-content {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

      &.hasList {
        min-height: 500px;
      }

      ul {
        padding: 0 40px;

        li {
          display: flex;
          padding: 20px 0;
          color: #1f1f40;
          font-size: 14px;
          border-bottom: 1px solid #ebebf2;

          &:last-child {
            border: none;
          }

          .play-btn {
            font-size: 14px;
            color: #3b86ff;
            cursor: pointer;
          }
        }
      }
    }

    .word-list-header {
      display: flex;
      padding: 0 40px;
      height: 90px;
      align-items: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .word-list-pagination {
      text-align: center;
      margin-top: 15px;
      padding-bottom: 100px;

      &.el-pagination--small {
        .el-pager li,
        .btn-prev,
        .btn-next {
          border: 1px solid rgba(199, 202, 222, 1);
          border-radius: 3px;
          margin: 0 3px;
          color: #c7cade;
        }

        .el-pager li.active {
          border: 1px solid rgba(59, 134, 255, 1);
          color: #3b86ff;
        }

        .btn-prev,
        .btn-next {
          padding: 0 4px;
        }

        .el-pager li.btn-quicknext,
        .el-pager li.btn-quickprev {
          border: none;
        }
      }
    }
  }
}

.del-word-type-dialog {
  border-radius: 10px;

  .el-button--primary {
    background: #3b86ff;
    border-color: #3b86ff;
    border-radius: 5px;
    padding: 4px 18px;
  }

  .el-message-box__header {
    padding-top: 33px;
  }

  .el-message-box__content {
    padding: 0 15px;
  }

  .el-button:not(.el-button--primary) {
    color: #3b86ff;
    padding: 4px 18px;
    border: none;

    .f-icon {
      color: #7c8084;
    }

    &.active,
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;

      .f-icon {
        color: #3b86ff;
      }
    }

    &:focus {
      &.active,
      &:hover {
        color: #3b86ff;
        background: #ecf3ff;
        border-color: #3b86ff;

        .f-icon {
          color: #3b86ff;
        }
      }

      color: #7c8084;
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
